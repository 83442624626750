import React, { useEffect, useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { graphql, Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import "scss/main.scss";

import SEO from "utils/Seo";
import theme from "utils/Theme";
import GlobalStyles from "utils/GlobalStyles";
import GetImage from "utils/getImage";
import StyledButton from "components/shared/StyledButton";
import { media } from "utils/Media";
import TestimonialBlockFilmFans from "components/new-york/slider";
import ReactPlayer from "react-player/youtube";
import NYFooterLinks from "components/new-york/nyFooterLinks";
import OriginalWestEndCast from "components/shared/OriginalWestEndCast";

const Button = styled(StyledButton)`
  width: 250px;
  @media ${media.md} {
    width: 300px;
  }
  @media ${media.lg} {
    width: 350px;
  }
`;

const LandingPageStyles = styled.div`
  width: 100%;
  height: 100vh;
  padding: 1rem 0;
  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 100%;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
    }
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
    /* .video {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      z-index:5;
    } */
    #videobg {
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      /* min-height: 65vh; */
      overflow: hidden;
      pointer-events: none;
      left: 50%;
      transform: translateX(-50%);

      @media ${media.lg} {
        /*             
            background: url(${(props) =>
          props.$headerXl}) no-repeat center center; */
        background-size: cover;
      }

      .video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-height: 200%;
        min-width: 100%;
        z-index: -99;
        background-size: cover;

        @media ${media.lg} {
          min-height: 140%;
          min-width: 140%;
        }
      }

      &:after {
        content: "";
        background-color: rgba(0, 0, 0, 0.55);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 101;
      }
      .v-desktop {
        display: none;
        @media ${media.md} {
          display: block;
        }
      }
      .v-mobile {
        .video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          min-height: 140%;
          min-width: 140%;
          z-index: -99;
          background-size: cover;

          @media ${media.lg} {
            min-height: 140%;
            min-width: 140%;
          }
        }
        @media ${media.md} {
          display: none;
        }
      }
    }
  }
  .content {
    position: relative;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* min-height: 500px; */
    .logo {
      position: absolute;
      width: 100%;
      top: 0;
      text-align: center;

      img {
        width: 150px;
      }
      .gatsby-image-wrapper {
        transform: translateX(-1000px);
        opacity: 0;
      }
    }
    h1 {
      text-align: center;
      color: #fff;
      z-index: 5;
      position: relative;
      font-size: 2rem;
      transform: translateX(-1000px);
      opacity: 0;
    }
    .buttons {
      margin-top: 1.5rem;
      transform: translateX(-2000px);
      opacity: 0;
      display: flex;
      flex-direction: column;
      /* max-width: 1200px; */
      gap: 1rem;
    }
    .credit {
    position: relative;
    z-index: 40;
    text-align: right;
    margin-top: 1rem;
    font-size: 15px;
    color: #fff;
    opacity: 0;
    @media ${media.md} {
        display: none;
    }
  }

    @media ${media.md} {
      width: 90%;
      height: 70%;
      margin: 0 auto;
      align-items: center;
      h1 {
        text-align: center;
        font-size: 2.7rem;
        margin-top: 1.5rem;
      }
      .logo {
        position: relative;
        text-align: center;
        img {
          width: 250px;
        }
      }
      .buttons {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    @media ${media.lg} {
      height: 72%;
    }
    @media ${media.xl} {
      height: 65%;
      h1 {
        font-size: 3.5rem;
      }
    }
    @media ${media.xxl} {
      height: 70%;
      h1 {
        font-size: 80px;
      }
    }
  }

  .lines {
    position: relative;
    display: none!important;
    width: 100%;
    height: 20px;
    z-index: 101;
    bottom: 1rem;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    div {
      width: 0%;
      height: 2px;
      background-color: ${(props) => props.theme.colors.red};
      margin: 0 0 0 auto;
      &:nth-child(2) {
        background-color: ${(props) => props.theme.colors.orange};
        width: 0%;
        margin: 0 0 0 auto;
      }
      &:nth-child(3) {
        background-color: ${(props) => props.theme.colors.yellow};
        width: 0%;
        margin: 0 0 0 auto;
      }
    }
  }
  .lower {
    padding: 0.5rem 0;
    .gradient {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6.5rem;
      background: linear-gradient(rgba(0, 0, 0, 0.05), #000);
      @media ${media.md} {
        display: none;
      }
    }
    .links {
      position: relative;
      text-align: center;
      color: #fff;
      line-height: 1.5;
      z-index: 10;
      opacity: 0;
      .desktop {
        display: none;
      }
      a {
        font-family: ${(props) => props.theme.font.family.base}!important;
        color: #fff !important;
        text-decoration: none;
        transition: color 0.25s ease-in-out;
        cursor: pointer;
        &:hover {
          color: ${(props) => props.theme.colors.red}!important;
        }
      }
    }
    @media ${media.md} {
      width: 90%;
      margin: 0 auto;

      .links {
        font-size: 0.9rem;
        .desktop {
          display: inline;
        }
        .mob {
          display: none;
        }
      }
    }
    @media ${media.xl} {
      padding: 0;
      .links-postion {
        position: absolute;
        bottom: 5px;
        z-index: 99;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
`;
const Landing = ({ data }) => {
  const { logo, backgroundImage } = data;
  const videoRef = useRef();
  const [playing, setPlaying] = useState(false);

  //* Main Animation
  const contentRef = useRef();
  const s = gsap.utils.selector(contentRef);
  useEffect(() => {
    contentRef.current = gsap
      .timeline({ delay: 1, ease: "power2.out" })
      .to(s(".gatsby-image-wrapper"), { x: 0, opacity: 1 })
      .to(s("h1"), { x: 0, opacity: 1 })
      .to(s(".buttons"), { x: 0, opacity: 1, stagger: 1 })
      .to(s(".credit"), {  opacity: 1, stagger: 1 });
  }, []);

  //* Lines Animation
  const lineRef = useRef();
  // const q = gsap.utils.selector(lineRef);
  // useEffect(() => {
  //   lineRef.current = gsap
  //     .timeline({ delay: 2.5, ease: "power2.out" })
  //     .to(q(".l-1"), { width: "100%" })
  //     .to(q(".l-2"), { width: "98%" })
  //     .to(q(".l-3"), { width: "95.5%" });
  // }, []);

  //* Lower Animation
  const lowerRef = useRef();
  const a = gsap.utils.selector(lowerRef);
  useEffect(() => {
    lowerRef.current = gsap
      .timeline({ delay: 2.75, ease: "power2.out" })
      .to(a("#slider"), { opacity: 1 })
      .to(a(".links"), { opacity: 1, duration: 3, delay: 0.5 });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPlaying(true);
    }, 2500);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <GlobalStyles />
      <LandingPageStyles>
        <div className="background">
        <OriginalWestEndCast $index />
          <div id="videobg">
            <div className="v-desktop">
              <ReactPlayer
                url="https://youtu.be/jINwFutYYSs"
                config={{
                  youtube: {
                    playerVars: {
                      playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 0,
                        controls: 0,
                        autohide: 1,
                        rel: 0,
                        modestbranding: 1,
                        showinfo: 0,
                        loop: 1,
                        mute: 1,
                        playsinline: 1,
                      },
                    },
                  },
                }}
                playing={playing}
                muted
                loop
                playsInline
                // onReady={this.onReady}
                style={{
                  opacity: playing ? "1" : "0",
                  transition: "all .5s ease",
                }}
                className="video"
              />
            </div>
            <div className="v-mobile">
              <ReactPlayer
                url="https://youtu.be/wQRqYYRqz7Q"
                config={{
                  youtube: {
                    playerVars: {
                      playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 0,
                        controls: 0,
                        autohide: 1,
                        rel: 0,
                        modestbranding: 1,
                        showinfo: 0,
                        loop: 1,
                        mute: 1,
                        playsinline: 1,
                      },
                    },
                  },
                }}
                playing={playing}
                muted
                loop
                playsInline
                // onReady={this.onReady}
                style={{
                  opacity: playing ? "1" : "0",
                  transition: "all .5s ease",
                }}
                className="video"
              />
            </div>
          </div>
          <StaticImage
            src="../images/HomePage/bg-home-header-1920-new.jpg"
            alt=""
            className="test"
          />
        </div>
        <div className="content" ref={contentRef}>
          <div className="logo">
            <GetImage data={logo} />
          </div>
          <h1>Choose your destination</h1>
          <div className="buttons">
              <Button  href="/london/">
                London
              </Button>
            {/* <Button  href="/new-york/">
              New York
            </Button> */}
            <Button  href="/northamerica/">
              North American Tour
            </Button>
            <Button  href="https://www.shiki.jp/applause/backtothefuture/" target="_blank">
            TOKYO
            </Button>
            <Button  href="https://backtothefuturemusical.com.au/" target="_blank">
            SYDNEY
            </Button>
            <Button  href="https://www.royalcaribbean.com/gbr/en/cruise-activities/back-to-the-future" target="_blank">
            Royal Caribbean
            </Button>
            {/* <Button  href="/audition/">
            OPEN AUDITIONS
            </Button> */}
          </div>
        <p className="credit">Original West End Cast</p>
        </div>
        <div className="lines" ref={lineRef}>
          <div className="l-1" />
          <div className="l-2" />
          <div className="l-3" />
        </div>
        <div className="lower" ref={lowerRef}>
          <div className="gradient" />
          <TestimonialBlockFilmFans $opacity />
          <div className="links-postion">
            <NYFooterLinks />
          </div>
        </div>
      </LandingPageStyles>
    </ThemeProvider>
  );
};

export default Landing;

export const LandingQuery = graphql`
  query LandingQuery {
    logo: file(relativePath: { eq: "BTTF-LOGO-2020-NEW.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
    backgroundImage: file(
      relativePath: { eq: "HomePage/bg-home-header-1920-new.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
  }
`;
